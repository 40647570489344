import { sentry } from '@/plugins/sentry';
/**
 * Function to cleanup data after logout
 */
export function logoutCleanup() {
  try {
    window.localStorage.removeItem('bill-alerts');
    window.sessionStorage.removeItem('lastTrusWindowDayModalSeen');
  } catch (error) {
    sentry.captureException(error);
  }
}
